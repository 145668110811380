import Swiper from 'swiper/bundle';
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper/core';
SwiperCore.use([Navigation, Pagination, Scrollbar]);

const mvSwiper = new Swiper('.mvSlider.swiper-container', {
  loop: true,
  speed:3000,
  autoplay: {
    delay: 3000,
  },
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  pagination: {
    el: '.swiper-pagination',
  },

  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  scrollbar: {
    el: '.swiper-scrollbar',
  },
});


const cardSlider = new Swiper('.cardSlider.swiper-container', {
  loop: true,
  centeredSlides:true,
  autoplay: {
    delay: 3000,
  },
  pagination: {
    el: '.swiper-pagination',
  },
  spaceBetween:30,
  breakpoints: {
    300: {
      slidesPerView: 1.3,
      spaceBetween: 20
    },
    576: {
      slidesPerView: 1.8,
      spaceBetween: 20
    },
    768: {
      slidesPerView: 2.4,
      spaceBetween: 20
    },
    991: {
      slidesPerView: 3.2,
      spaceBetween: 20
    },
    1100: {
      slidesPerView: 3.5,
      spaceBetween: 30
    },
    1400: {
      slidesPerView: 4.5,
      spaceBetween: 40
    },
    1750: {
      slidesPerView: 5.5,
      spaceBetween: 40
    }
  }
});

const aSwiper = new Swiper('.aSlider.swiper-container', {
  loop: true,
  slidesPerView: 1,
  centeredSlides:true,
  spaceBetween: 0,
  autoplay: {
    delay: 3000,
  },

  pagination: {
    el: '.swiper-pagination',
  },

  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    300: {
      spaceBetween: 10,
      slidesPerView: 1.2,
    },
    576: {
      slidesPerView: 1,
    }
  }
  
});