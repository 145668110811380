import fullpage from "fullpage.js";
var currentPage = 1;

function build() {
  var barHeight = $(window).height() - 234;
  $("#slideBar").animate({ height: barHeight + "px" }, 500);

  var totalSection = $(".section").length;
  var barLength = barHeight - (barHeight / totalSection) * (currentPage - 1);
  $(".slideBar__active").hide();
  $(".slideBar__bar").animate({ height: barLength + "px" }, 500);
  $(".slideBar__active").html(currentPage);
  $(".slideBar__active").animate(
    { opacity: "show" },
    { duration: 1100, easing: "swing" }
  );
}

var fullPageInstance = new fullpage("#slide", {
  navigation: false,
  verticalCentered: true,
  anchors: ["", "about", "facility", "event", "artist", "access"],
  menu: "#slideMenu",
  easingcss3: "ease-out",
  scrollingSpeed: 600,
  afterRender: function () {
    build();
  },
  afterResize: function () {
    build();
  },
  afterLoad: function (origin, destination, direction) {
    var loadedSection = this;

    if (destination.anchor == "") {
      $(".sp-logo").fadeOut();
    } else {
      $(".sp-logo").fadeIn();
    }
  },
  onLeave: function (origin, destination, direction) {
    currentPage = destination.index + 1;
    build();
  },
});
